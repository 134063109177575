<div *ngIf="this.element$ | async as element" class="sis-sismap-sidepane-div" [ngClass]="this.className$ | async">
  <ion-header>
    <ion-toolbar>
      <ion-row>
        <ion-col class="icon-col"
          ><div class="icon-div icon-div-asset-type" [innerHTML]="assetTypeIcon$ | async"></div
        ></ion-col>
        <ion-col
          ><div class="header-title-text">
            <span *ngIf="element.label">{{ element.label }} - </span>
            <span>{{ element.name }}</span>
          </div>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>
  <ion-content [forceOverscroll]="false">
    <div class="content-div">
      <ion-row *ngIf="element.status && element.status !== 0">
        <ion-col>
          <div *ngIf="element.status === 2">
            <span class="status-box open-status" translate>webreport.term.open</span>
            <span *ngIf="element.subStatus === 201" class="sub-status" translate>general.term.circulation</span>
            <span *ngIf="element.subStatus === 202" class="sub-status" translate>general.term.convoy</span>
          </div>
          <div *ngIf="element.status === 1">
            <span translate class="status-box closed-status">webreport.term.closed</span>
            <span *ngIf="element.subStatus === 101" class="sub-status" translate>general.term.revision</span>
            <span *ngIf="element.subStatus === 102" class="sub-status" translate>general.term.storm</span>
            <span *ngIf="element.subStatus === 103" class="sub-status" translate>general.term.malfunction</span>
          </div>
          <div *ngIf="element.status === 3" class="status-box preparation-status" translate>
            webreport.term.preparation
          </div>
        </ion-col>
      </ion-row>
    </div>

    <div class="content-div" *ngIf="element.info">
      <ion-row>
        <ion-col class="sub-title" translate>general.term.information</ion-col>
      </ion-row>
      <ion-row>
        <ion-col [innerHTML]="element.info.split('\n').join('<br>') | clickableUrl"></ion-col>
      </ion-row>
    </div>

    <div
      class="content-div"
      *ngIf="
        element.lengthMeter ||
        element.firstRide ||
        element.lastRide ||
        element.throughput ||
        element.travelTime ||
        element.duration ||
        element.elevation
      "
    >
      <ion-row>
        <ion-col class="sub-title" translate>general.term.details</ion-col>
      </ion-row>
      <ion-row *ngIf="element.lengthMeter">
        <ion-col>
          {{ ('general.term.length' | translate) + ': ' }}
        </ion-col>
        <ion-col>
          {{ element.lengthMeter + ' m' }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.firstRide">
        <ion-col>
          {{ ('general.term.firstRide' | translate) + ': ' }}
        </ion-col>
        <ion-col>
          {{ element.firstRide }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.firstRide">
        <ion-col>
          {{ ('general.term.lastRide' | translate) + ': ' }}
        </ion-col>
        <ion-col>
          {{ element.lastRide }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.throughput">
        <ion-col>
          {{ ('general.term.throughput' | translate) + ': ' }}
        </ion-col>
        <ion-col>
          {{ element.throughput + ' Pers./Hr.' }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.travelTime">
        <ion-col>
          {{ ('general.term.travelTime' | translate) + ': ' }}
        </ion-col>
        <ion-col>
          {{ element.travelTime + ' Min.' }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.duration">
        <ion-col>
          {{ ('general.term.hikeDuration' | translate) + ': ' }}
        </ion-col>
        <ion-col>
          {{ element.duration | hoursMinutes }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.elevation">
        <ion-col>
          {{ ('general.term.elevation' | translate) + ': ' }}
        </ion-col>
        <ion-col>
          {{ element.elevation + ' m' }}
        </ion-col>
      </ion-row>
    </div>

    <div class="content-div contact" *ngIf="element.contact || element.location || element.phone || element.email">
      <ion-row>
        <ion-col class="sub-title" translate>general.term.contact</ion-col>
      </ion-row>
      <ion-row *ngIf="element.contact">
        <ion-col>
          {{ element.contact }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.location">
        <ion-col>
          {{ element.location }}
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.phone">
        <ion-col size="1">
          <ion-icon name="call-outline"></ion-icon>
        </ion-col>
        <ion-col>
          <a [href]="'tel:' + element.phone">{{ element.phone }}</a>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="element.email">
        <ion-col size="1">
          <ion-icon name="mail-outline"></ion-icon>
        </ion-col>
        <ion-col>
          <a [href]="'mailto:' + element.email">{{ element.email }}</a>
        </ion-col>
      </ion-row>
    </div>

    <div class="content-div contact" *ngIf="element.website">
      <ion-row>
        <ion-col class="sub-title" translate>general.term.website</ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="sis-global-text-overflow-ellipsis">
          <a [href]="element.website" target="_blank" rel="noreferrer noopener">{{ element.website }}</a>
        </ion-col>
      </ion-row>
    </div>

    <div class="content-div" *ngIf="element.operatingHoursEnabled">
      <ion-row>
        <ion-col class="sub-title" translate>general.term.operatingHours</ion-col>
      </ion-row>
      <ion-row class="operating-hours-row" *ngFor="let operatingHours of operatingHours$ | async">
        <ion-col size="5" class="sis-global-text-overflow-ellipsis" [innerHTML]="operatingHours[0]"></ion-col>
        <ion-col class="sis-global-text-overflow-ellipsis" [innerHTML]="operatingHours[1]"></ion-col>
      </ion-row>
    </div>
  </ion-content>
</div>
