<ng-container *ngIf="this.isWinterSeason && this.meteoInfosReport.length > 0">
  <ion-row class="sis-webreport-meteo-category-title">
    <ion-col [ngStyle]="{ 'font-size': this.config?.assetTitleFontSize, color: this.config?.assetTitleColor }">
      {{
        this.showWeatherTitle
          ? ('webreport.assetCategoryWeatherAndSnowConditions' | translate)
          : ('webreport.assetCategorySnowConditions' | translate)
      }}
    </ion-col>
  </ion-row>

  <ion-row *ngFor="let row of this.meteoInfosReport; trackBy: trackByIndex" class="sis-webreport-meteo-row">
    <ion-col *ngFor="let weatherStation of row; trackBy: trackByGuid" [size]="this.columnSize">
      <div class="sis-webreport-meteo-div">
        <ion-grid class="sis-webreport-meteo-item-grid">
          <ion-row class="sis-webreport-meteo-station-title">
            <ion-col style="align-content: center">
              <span style="font-size: 20px">{{ this.weatherStation.location }}</span>
              <span style="margin-left: 15px">{{
                this.weatherStation.altitude.slice(0, -1) + ' ' + ('webreport.term.metersAboveSeaLevel' | translate)
              }}</span>
            </ion-col>
            <ion-col size="2" *ngIf="this.showWeather">
              <ion-img
                class="sis-webreport-meteo-image"
                [src]="
                  'assets/icons-meteo/desktop/' +
                  (weatherStation.weather ? weatherStation.weather : 'placeholder-meteo') +
                  '.svg'
                "
              ></ion-img>
            </ion-col>
          </ion-row>

          <!-- Meteo Icons: https://www.svgrepo.com/collection/framework7-line-icons/ how to document?-->
          <ion-row *ngIf="this.showTemperature" class="sis-webreport-meteo-station-data">
            <ion-col>
              {{ 'webreport.term.temperature' | translate }}
            </ion-col>
            <ion-col>
              {{ weatherStation.temperature + ' °C' }}
            </ion-col>
          </ion-row>

          <ion-row *ngIf="this.showWindSpeed && weatherStation.windSpeed" class="sis-webreport-meteo-station-data">
            <ion-col>
              {{ 'webreport.term.windSpeed' | translate }}
            </ion-col>
            <ion-col>
              {{ weatherStation.windSpeed + ' km/h' }}
            </ion-col>
          </ion-row>

          <ion-row
            *ngIf="this.showSnowCondition && weatherStation.snowConditionId !== 0"
            class="sis-webreport-meteo-station-data"
          >
            <ion-col>
              {{ 'webreport.term.snowCondition' | translate }}
            </ion-col>
            <ion-col>
              {{ this.getSnowConditionTranslationString(weatherStation.snowConditionId) | translate }}
            </ion-col>
          </ion-row>

          <ion-row
            *ngIf="this.showSlopeCondition && weatherStation.slopeConditionId !== 0"
            class="sis-webreport-meteo-station-data"
          >
            <ion-col>
              {{ 'webreport.term.slopeCondition' | translate }}
            </ion-col>
            <ion-col>
              {{ this.getSlopeConditionTranslationString(weatherStation.slopeConditionId) | translate }}
            </ion-col>
          </ion-row>

          <ion-row *ngIf="this.showFreshSnow" class="sis-webreport-meteo-station-data">
            <ion-col>
              {{ 'webreport.term.freshSnow' | translate }}
            </ion-col>
            <ion-col>
              {{ weatherStation.freshSnow + ' cm' }}
            </ion-col>
          </ion-row>

          <ion-row *ngIf="this.showSnowDepth" class="sis-webreport-meteo-station-data">
            <ion-col>
              {{ 'webreport.term.snowDepth' | translate }}
            </ion-col>
            <ion-col>
              {{ weatherStation.snowDepth + ' cm' }}
            </ion-col>
          </ion-row>

          <ion-row *ngIf="this.showLatestSnowfall" class="sis-webreport-meteo-station-data">
            <ion-col>
              {{ 'webreport.term.latestSnowfall' | translate }}
            </ion-col>
            <ion-col>
              {{ weatherStation.latestSnowfall }}
            </ion-col>
          </ion-row>

          <ion-row *ngIf="this.showAvalancheDangerLevel" class="sis-webreport-meteo-station-data">
            <ion-col>
              {{ 'webreport.term.avalancheDangerLevel' | translate }}
            </ion-col>
            <ion-col>
              {{ this.getAvalancheDangerLevelTranslationString(weatherStation.avalancheDangerLevel) | translate }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-col>
  </ion-row>
</ng-container>
