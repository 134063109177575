import { PointExpression } from 'leaflet';
import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { AssetSubStatus } from 'src/app/maps/domain/livedata/substatus.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class Lift implements MapElement {
  static readonly defaultColor: string = 'black';
  static readonly defaultIconSize: PointExpression = [22, 22];
  static readonly defaultWeight = 5;
  static readonly empty: Lift = new Lift({ guid: null, status: AssetStatus.Disabled });

  readonly category = SisMapAssetCategory.Lift;

  guid: string;
  name: string;
  nameEn: string;
  type: string;
  label: string;
  info?: string;
  firstRide?: string;
  lastRide?: string;
  throughput?: number;
  travelTime?: number;
  website?: string;
  season: Season;
  status: AssetStatus;
  subStatus?: AssetSubStatus;
  path?: string;
  pathDashArray?: string;
  pathWeight?: number;
  pathColor?: string;
  pathBackgroundColor?: string;
  paneName = 'lift';

  defaultPathColor: string;
  defaultPathWeight: number;

  iconPositions: MapPosition[];
  iconWidth: number;
  iconHeight: number;
  iconImageName?: string;

  constructor(init?: Partial<WritablePart<Lift>>) {
    Object.assign(this, init);
  }
}
