import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreakdownInfoPageModule } from 'src/app/breakdowninfo/breakdowninfo.module';
import { ErrorPageModule } from 'src/app/error/error.module';
import { SisMapPageModule } from 'src/app/maps/sismap/sismap.module';
import { WindmonitorModule } from 'src/app/maps/windmonitor/windmonitor.module';
import { OperatingInfoPageModule } from 'src/app/operating-info/operating-info.module';
import { QuickstartPage } from 'src/app/quickstart/quickstart.page';
import { SiscontrolPage } from 'src/app/siscontrol/siscontrol.page';
import { WebReportModule } from 'src/app/webreport/webreport.module';

const routes: Routes = [
  {
    path: '',
    component: SiscontrolPage,
    pathMatch: 'full',
  },
  {
    path: ':sisId/sismap/:alias/:season',
    loadChildren: () => SisMapPageModule,
  },
  {
    path: ':sisId/windmonitor/:alias/:season',
    loadChildren: () => WindmonitorModule,
  },
  {
    path: 'quickstart',
    component: QuickstartPage,
  },
  {
    path: 'breakdowninfo/:breakdownInfoGuid',
    loadChildren: () => BreakdownInfoPageModule,
  },
  {
    path: 'report/:urlName',
    loadChildren: () => WebReportModule,
  },
  {
    path: 'operatinginfo/:operatinginfoUrl',
    loadChildren: () => OperatingInfoPageModule,
  },
  {
    path: '**',
    loadChildren: () => ErrorPageModule,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
