import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { Gastro } from 'src/app/maps/domain/masterdata/gastro.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';

export class GastroAdapter {
  static adapt(item: any): Gastro {
    if (!item) {
      return Gastro.empty;
    }

    const defaultIconSize = item.defaultIconSize ? item.defaultIconSize : Gastro.defaultIconSize;

    let positions: MapPosition[] = [];
    if (item.iconPosition && item.iconPosition !== '') {
      let iconPositions: MapPosition[] | MapPosition = JSON.parse(item.iconPosition);
      if (!Array.isArray(iconPositions)) {
        iconPositions = [iconPositions];
      }
      positions = iconPositions;
    }

    return new Gastro({
      guid: item.guid,
      name: item.name,
      nameEn: item.nameEn,
      season: item.season,
      status: item.status != null ? item.status : AssetStatus.NoStatus,
      type: item.type,
      contact: item.contact,
      location: item.location,
      phone: item.phone,
      email: item.email,
      website: item.website,
      info: item.info,
      operatingHoursEnabled: item.operatingHoursEnabled,
      operatingHours: item.operatingHoursEnabled ? item.operatingHours : [],
      iconImageName: item.type,
      iconPositions: positions,
      iconWidth: defaultIconSize[0],
      iconHeight: defaultIconSize[1],
    });
  }

  static adaptForApi(gastro: Gastro): any {
    return {
      guid: gastro.guid,
      assetCategory: gastro.category,
      iconPosition: JSON.stringify(gastro.iconPositions),
    };
  }
}
