import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sis-siscontrol',
  template: '',
})
export class SiscontrolPage implements OnInit {
  ngOnInit(): void {
    window.location.href = 'https://sisag.ch/produkte/siscontrol';
  }
}
