import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { Status } from 'src/app/maps/domain/livedata/status.model';
import { AssetSubStatus } from 'src/app/maps/domain/livedata/substatus.enum';

export class StatusAdapter {
  static adapt(item: any[]): Status[] {
    if (!item?.length) {
      return [];
    }

    return item.map((e) => ({
      assetGuid: e.RowKey,
      assetStatus: e.Status ?? AssetStatus.NoStatus,
      assetSubStatus: e.SubStatus ?? AssetSubStatus.Disabled,
    }));
  }
}
