import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { SisMediaItem } from 'src/app/webreport/domain/sismedia-item.model';
import { WebReportConfig } from 'src/app/webreport/domain/webreport-config.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-sismedia-item',
  templateUrl: './sismedia-item.component.html',
  styleUrls: ['./sismedia-item.component.scss'],
})
export class SisMediaItemComponent extends Unsubscriber implements OnInit, OnChanges {
  @Input() item: SisMediaItem;
  @Input() bigScreenMode: boolean;
  @Input() config: WebReportConfig;
  @Input() mobileStyleTenant: boolean;
  @Input() longestLabelLength: number;

  name: string;
  icon: string;
  invert: boolean;
  showingLabelLength: string;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    if (this.item) {
      this.icon = `${environment.baseUrlPublicAssets}/icons/sismedia/styles/sisag/${this.item.type}.svg`;
      this.invert = !(this.item.type.startsWith('piste') || this.item.type.endsWith('-color'));
      this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
        this.name = event.lang === 'en' ? this.item.nameEn : this.item.name;
      });
    }
  }

  ngOnChanges(): void {
    this.showingLabelLength = this.bigScreenMode
      ? `${this.longestLabelLength + 10}px`
      : `${Math.min(36, this.longestLabelLength) + 4}px`;
    this.name =
      (this.translateService.currentLang || this.translateService.defaultLang) === 'en'
        ? this.item.nameEn
        : this.item.name;
  }
}
