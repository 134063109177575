import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { from, map, of, switchMap } from 'rxjs';
import { Gastro } from 'src/app/maps/domain/masterdata/gastro.model';
import { OperatingHours } from 'src/app/maps/domain/masterdata/operating-hours.model';
import { IconService } from 'src/app/maps/map/icon-service';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { MapStyleService } from 'src/app/maps/map/style/map-style.service';

@Component({
  selector: 'sis-asset-sidepane-content',
  templateUrl: './asset-sidepane-content.component.html',
  styleUrls: ['./asset-sidepane-content.component.scss'],
})
export class AssetSidepaneContentComponent {
  readonly element$ = this.mapStateService.selectedElement$.pipe(map(({ element }) => element));

  readonly className$ = from(this.mapStyleService.getStyleName()).pipe(map((name) => `sis-menu-${name}`));

  readonly assetTypeIcon$ = this.element$.pipe(
    switchMap((element) => {
      if (element?.iconImageName) {
        return from(this.iconService.getAssetTypeIconHtml(element)).pipe(
          map((iconString) => this.sanitizer.bypassSecurityTrustHtml(iconString))
        );
      }
      return of(null);
    })
  );

  readonly operatingHours$ = this.element$.pipe(
    map((element) => (element ? this.createOperatingHours((element as Gastro).operatingHours) : []))
  );

  constructor(
    private mapStyleService: MapStyleService,
    private mapStateService: MapStateService,
    private iconService: IconService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService
  ) {}

  private createOperatingHours(operatingHours: OperatingHours[]): string[][] {
    operatingHours.sort((a, b) => a.weekday - b.weekday);

    const now = new Date();
    let dayNow = now.getDay();
    if (dayNow === 0) {
      dayNow = 7;
    }

    const weekDates = this.getWeekDates();
    const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    return operatingHours.map((o) => {
      let dayName = weekdays[o.weekday - 1];
      switch (o.weekday) {
        case dayNow:
          dayName = this.translateService.instant(`general.term.today`);
          break;
        case dayNow + 1:
          dayName = this.translateService.instant(`general.term.tomorrow`);
          break;
        default:
          dayName = this.translateService.instant(`general.term.weekday.${o.weekday}`);
          break;
      }

      dayName += ', ' + weekDates[o.weekday - 1];

      let timeString: string;
      switch (o.status) {
        case 0:
          timeString = this.translateService.instant('operatinghours.term.closed');
          break;
        case 1:
          timeString = `${o.from1} - ${o.until1} / ${o.from2} - ${o.until2}`;
          break;
        case 2:
          timeString = `${o.from1} - ${o.until2}`;
          break;
      }

      if (o.weekday === dayNow) {
        dayName = `<b>${dayName}</b>`;
        timeString = `<b>${timeString}</b>`;
      }

      return [dayName, timeString];
    });
  }

  private getWeekDates(): string[] {
    const todayDayOfWeek = new Date().getDay();

    const dayOffset = todayDayOfWeek === 0 ? -6 : 1 - todayDayOfWeek;

    const monday = new Date();
    monday.setDate(monday.getDate() + dayOffset);

    const weekDates: string[] = [];
    for (let i = 0; i < 7; i++) {
      const currentDay = new Date(monday);
      currentDay.setDate(monday.getDate() + i);
      const dateString = currentDay.getDate() + '.' + (currentDay.getMonth() + 1) + '.';
      weekDates.push(dateString);
    }

    return weekDates;
  }
}
