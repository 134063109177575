import { Component, Input, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { Gastro } from 'src/app/maps/domain/masterdata/gastro.model';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { POI } from 'src/app/maps/domain/masterdata/poi.model';
import { Slope } from 'src/app/maps/domain/masterdata/slope.model';
import { Trail } from 'src/app/maps/domain/masterdata/trail.model';
import { AssetSidepaneContentComponent } from 'src/app/maps/map/asset-sidepane/asset-sidepane-content/asset-sidepane-content.component';
import { HoverService } from 'src/app/maps/map/hover.service';
import { MapStateService } from 'src/app/maps/map/map-state.service';

@Component({
  selector: 'sis-asset-sidepane',
  templateUrl: './asset-sidepane.component.html',
  styleUrls: ['./asset-sidepane.component.scss'],
})
export class AssetSidepaneComponent extends Unsubscriber implements OnInit {
  @Input() editMode: boolean;
  @Input() bigScreenMode: boolean;

  showSpinner = true;
  element: MapElement;

  constructor(
    private mapStateService: MapStateService,
    private hoverService: HoverService,
    private modalCtrl: ModalController,
    private menuCtrl: MenuController
  ) {
    super();
  }

  ngOnInit(): void {
    this.mapStateService.elementClicked$.pipe(takeUntil(this.onDestroy$)).subscribe(async ({ element }) => {
      if (element == null) {
        return;
      }
      this.element = element;
      const categories = [Lift, Slope, Trail, POI, Gastro];
      if (!this.editMode && categories.some((category) => element instanceof category)) {
        this.showSpinner = false;
        if (this.bigScreenMode) {
          await this.menuCtrl.open('asset-sidepane-menu');
        } else {
          await this.createBottomSheetModal(element);
        }
      }
    });
  }

  private async createBottomSheetModal(element: MapElement): Promise<void> {
    const initialBreakpoint = element instanceof Gastro ? 0.8 : 0.5;
    const modal = await this.modalCtrl.create({
      component: AssetSidepaneContentComponent,
      breakpoints: [0, 0.3, 0.5, 0.8, 0.9999],
      initialBreakpoint,
      handle: true,
      cssClass: 'sis-sismap-bottom-sheet-div',
      mode: 'ios',
      showBackdrop: false,
    });
    modal.present();
    modal.onWillDismiss().then(() => {
      this.mapStateService.clickElement(null, null);
      this.hoverService.hover({ guid: element.guid, hover: false, iconHovered: false });
    });
  }

  resetMenu(): void {
    this.showSpinner = true;
    this.mapStateService.clickElement(null, null);
    this.hoverService.hover({ guid: this.element.guid, hover: false, iconHovered: false });
  }
}
