import { SisMediaItem } from 'src/app/webreport/domain/sismedia-item.model';

export class SisMediaItemAdapter {
  static adapt(items: any): SisMediaItem[] {
    const result: SisMediaItem[] = [];

    if (items) {
      items.forEach((e: any) => {
        result.push(
          new SisMediaItem({
            guid: e.guid,
            name: e.name,
            nameEn: e.nameEn ?? e.name,
            assetCategory: e.assetCategory,
            order: e.exportOrder ?? 0,
            status: e.status,
            type: e.type?.toLowerCase(),
            typeDescription: e.typeDescription,
            season: e.season,
            tenantAlias: e.tenantAlias,
            label: e.label ?? '',
            lengthMeter: e.lengthMeter ?? 0,
          })
        );
      });
    }

    return result;
  }
}
