import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';

export class LiftAdapter {
  static adapt(item: any): Lift {
    if (!item) {
      return Lift.empty;
    }

    const defaultColor = item.defaultColor ? item.defaultColor : Lift.defaultColor;
    const weight = item.weight ? Number(item.weight) : null;
    const defaultIconSize = item.defaultIconSize ? item.defaultIconSize : Lift.defaultIconSize;

    let positions: MapPosition[] = [];
    if (item.iconPosition && item.iconPosition !== '') {
      let iconPositions: MapPosition[] | MapPosition = JSON.parse(item.iconPosition);
      if (!Array.isArray(iconPositions)) {
        iconPositions = [iconPositions];
      }
      positions = iconPositions;
    }

    return new Lift({
      guid: item.guid,
      name: item.name,
      nameEn: item.nameEn,
      label: item.label,
      info: item.info,
      firstRide: item.firstRide?.replace(/(\d{2}:\d{2}):\d{2}/gi, '$1'),
      lastRide: item.lastRide?.replace(/(\d{2}:\d{2}):\d{2}/gi, '$1'),
      throughput: item.throughput,
      travelTime: item.travelTime,
      website: item.website,
      type: item.type,
      season: item.season,
      status: item.status != null ? item.status : AssetStatus.NoStatus,
      subStatus: item.subStatus,
      path: item.path,
      pathDashArray: item.dashArray,
      pathWeight: weight,
      pathColor: item.color,
      pathBackgroundColor: item.backgroundColor,
      defaultPathColor: defaultColor,
      defaultPathWeight: Lift.defaultWeight,
      iconPositions: positions,
      iconWidth: defaultIconSize[0],
      iconHeight: defaultIconSize[1],
      iconImageName: item.type,
    });
  }

  static adaptForApi(lift: Lift): any {
    const path = lift.path || null;
    const color = lift.pathColor === Lift.defaultColor ? null : lift.pathColor || null;
    const backgroundColor = lift.pathBackgroundColor || null;
    const weight = lift.pathWeight === Lift.defaultWeight ? null : lift.pathWeight || null;
    const dashArray = lift.pathDashArray || null;

    return {
      guid: lift.guid,
      assetCategory: lift.category,
      path,
      iconPosition: JSON.stringify(lift.iconPositions),
      color,
      backgroundColor,
      dashArray,
      weight,
    };
  }
}
