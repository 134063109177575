import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedatabase.service';
import { StatusAdapter } from 'src/app/maps/domain/livedata/status.adapter';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { Status } from 'src/app/maps/domain/livedata/status.model';
import { AssetSubStatus } from 'src/app/maps/domain/livedata/substatus.enum';

@Injectable({
  providedIn: 'root',
})
export class StatusService extends LivedataServiceBase<Status> {
  readonly status$ = this.liveData$;

  constructor(http: HttpClient) {
    super(http, 'api/sismap/assetstatus', StatusAdapter.adapt);
  }

  findStatus(guid: string, statusArray: Status[]): AssetStatus {
    return statusArray.find((status) => status.assetGuid === guid)?.assetStatus;
  }

  findSubStatus(guid: string, statusArray: Status[]): AssetSubStatus {
    return statusArray.find((subStatus) => subStatus.assetGuid === guid)?.assetSubStatus;
  }
}
